export const topicColors = {
  // News
  'urn:srf:topic:tv:a709c610-b275-4c0c-a496-cba304c36712': {
    firstColor: '#b3131d',
    secondColor: '#3e0b14',
    className: 'from-[#b3131dB3] to-[#3e0b1480]'
  },
  // Sport
  'urn:srf:topic:tv:649e36d7-ff57-41c8-9c1b-7892daf15e78': {
    firstColor: '#FF0037',
    secondColor: '#AF001E',
    className: 'from-[#FF0037B3] to-[#AF001E80]'
  },
  // Dok
  'urn:srf:topic:tv:516421f0-ec89-43ba-823b-1b5ceec262f3': {
    firstColor: '#5FB281',
    secondColor: '#154e60',
    className: 'from-[#5FB281B3] to-[#154e6080]'
  },
  // Unterhaltung
  'urn:srf:topic:tv:641223fa-f112-4d98-8aec-cb22262a1182': {
    firstColor: '#c55cee',
    secondColor: '#0c1c68',
    className: 'from-[#c55ceeB3] to-[#0c1c6880]'
  },
  // Comedy
  'urn:srf:topic:tv:e52080fc-f36b-481e-955f-071b6c8d6dc3': {
    firstColor: '#ff6778',
    secondColor: '#920a1a',
    className: 'from-[#ff6778B3] to-[#920a1a80]'
  },
  // Film
  'urn:srf:topic:tv:67f812fd-19a3-4c22-9e6b-ec36e65a4703': {
    firstColor: '#bb3966',
    secondColor: '#190406',
    className: 'from-[#bb3966B3] to-[#19040680]'
  },
  // Serien
  'urn:srf:topic:tv:fa793c13-bebc-41b9-9710-bf8a34192c15': {
    firstColor: '#baead5',
    secondColor: '#010b40',
    className: 'from-[#baead5B3] to-[#010b4080]'
  },
  // Politik
  'urn:srf:topic:tv:bb7b21e0-1056-4e28-bac3-c610393b5b0f': {
    firstColor: '#3c788e',
    secondColor: '#1b3e48',
    className: 'from-[#3c788eB3] to-[#1b3e4880]'
  },
  // Wissen & Ratgeber
  'urn:srf:topic:tv:b58dcf14-96ac-4046-8676-fd8a942c0e88': {
    firstColor: '#7081b0',
    secondColor: '#202020',
    className: 'from-[#7081b0B3] to-[#20202080]'
  },
  // Gesellschaft
  'urn:srf:topic:tv:63f937e4-859e-42c4-a430-bdb74dd09645': {
    firstColor: '#4480a2',
    secondColor: '#20182c',
    className: 'from-[#4480a2B3] to-[#20182c80]'
  },
  // Kultur
  'urn:srf:topic:tv:882cb264-cf81-4a9c-b660-d42519b7ce28': {
    firstColor: '#c91d7d',
    secondColor: '#31041f',
    className: 'from-[#c91d7dB3] to-[#31041f80]'
  },
  // Religion
  'urn:srf:topic:tv:9a79b1de-cde8-4528-b304-d1ae1363f52f': {
    firstColor: '#836fcd',
    secondColor: '#36343f',
    className: 'from-[#836fcdB3] to-[#36343f80]'
  },
  // Talk
  'urn:srf:topic:tv:43741c59-317e-458b-ac38-c2b1c065c865': {
    firstColor: '#0075ad',
    secondColor: '#000022',
    className: 'from-[#0075adB3] to-[#00002280]'
  },
  // Kinder
  'urn:srf:topic:tv:1d7d9cfb-6682-4d5b-9e36-322e8fa93c03': {
    firstColor: '#00A4B3',
    secondColor: '#006973',
    className: 'from-[#00A4B3B3] to-[#00697380]'
  },
  // Jugend
  'urn:srf:topic:tv:a2d97206-0b85-4226-8afe-06e86ebd05b2': {
    firstColor: '#9fc885',
    secondColor: '#20281a',
    className: 'from-[#9fc885B3] to-[#20281a80]'
  },
  // Gebärdensprache
  'urn:srf:topic:tv:593eb926-d892-41ba-8b1f-eccbcfd7f15f': {
    firstColor: '#2bbf9b',
    secondColor: '#02291e',
    className: 'from-[#2bbf9bB3] to-[#02291e80]'
  },
  // Audiodeskription
  'urn:srf:topic:tv:4acf86dd-7ff7-45d3-baf8-33375340d976': {
    firstColor: '#3f4b70',
    secondColor: '#131a2d',
    className: 'from-[#3f4b70B3] to-[#131a2d80]'
  },
  // Info
  'urn:rts:topic:tv:665': {
    firstColor: '#3787FF',
    secondColor: '#0A1C33',
    className: 'from-[#3787FFB3] to-[#0A1C3380]'
  },
  // Sport
  'urn:rts:topic:tv:1095': {
    firstColor: '#F5F500',
    secondColor: '#BEB405',
    className: 'from-[#F5F500B3] to-[#BEB40580]'
  },
  // Culture
  'urn:rts:topic:tv:67132': {
    firstColor: '#415FAF',
    secondColor: '#23376B',
    className: 'from-[#415FAFB3] to-[#23376B80]'
  },
  // Séries et Films
  'urn:rts:topic:tv:1353': {
    firstColor: '#084165',
    secondColor: '#140953',
    className: 'from-[#084165B3] to-[#14095380]'
  },
  // Tataki
  'urn:rts:topic:tv:54537': {
    firstColor: '#FFE03E',
    secondColor: '#F98E73',
    className: 'from-[#FFE03EB3] to-[#F98E7380]'
  },
  // Documentaires
  'urn:rts:topic:tv:623': {
    firstColor: '#5C845B',
    secondColor: '#16280F',
    className: 'from-[#5C845BB3] to-[#16280F80]'
  },
  // Kids
  'urn:rts:topic:tv:2743': {
    firstColor: '#BCF6FF',
    secondColor: '#00D0EF',
    className: 'from-[#BCF6FFB3] to-[#00D0EF80]'
  },
  // Les archives
  'urn:rts:topic:tv:10193': {
    firstColor: '#EB2350',
    secondColor: '#A61637',
    className: 'from-[#EB2350B3] to-[#A6163780]'
  },
  // Émissions signées
  'urn:rts:topic:tv:59220': {
    firstColor: '#492b63',
    secondColor: '#271633',
    className: 'from-[#492b63B3] to-[#27163380]'
  },
  // Humour
  'urn:rts:topic:tv:73840': {
    firstColor: '#FF77CD',
    secondColor: '#663052',
    className: 'from-[#FF77CDB3] to-[#66305280]'
  },
  // Info
  'urn:rsi:topic:tv:7': {
    firstColor: '#546591',
    secondColor: '#2C3A50',
    className: 'from-[#546591B3] to-[#2C3A5080]'
  },
  // Sport
  'urn:rsi:topic:tv:8': {
    firstColor: '#CD4023',
    secondColor: '#90062E',
    className: 'from-[#CD4023B3] to-[#90062E80]'
  },
  // Kids
  'urn:rsi:topic:tv:11': {
    firstColor: '#DEA706',
    secondColor: '#E92466',
    className: 'from-[#DEA706B3] to-[#E9246680]'
  },
  // Film
  'urn:rsi:topic:tv:6000': {
    firstColor: '#017EB3',
    secondColor: '#011844',
    className: 'from-[#017EB3B3] to-[#01184480]'
  },
  // Serie TV
  'urn:rsi:topic:tv:600': {
    firstColor: '#23B6CD',
    secondColor: '#7C3184',
    className: 'from-[#23B6CDB3] to-[#7C318480]'
  },
  // Film e Telefilm
  'urn:rsi:topic:tv:3': {
    firstColor: '#017eb3',
    secondColor: '#7C3184',
    className: 'from-[#017eb3B3] to-[#7C318480]'
  },
  // Documentari
  'urn:rsi:topic:tv:40': {
    firstColor: '#44BDA8',
    secondColor: '#00324E',
    className: 'from-[#44BDA8B3] to-[#00324E80]'
  },
  // Musica
  'urn:rsi:topic:tv:100': {
    firstColor: '#D75959',
    secondColor: '#29336C',
    className: 'from-[#D75959B3] to-[#29336C80]'
  },
  // Intrattenimento
  'urn:rsi:topic:tv:4': {
    firstColor: '#D7B447',
    secondColor: '#B62019',
    className: 'from-[#D7B447B3] to-[#B6201980]'
  },
  // Archivi
  'urn:rsi:topic:tv:1': {
    firstColor: '#B5344E',
    secondColor: '#480010',
    className: 'from-[#B5344EB3] to-[#48001080]'
  },
  // Oltre la TV
  'urn:rsi:topic:tv:9': {
    firstColor: '#e74d6d',
    secondColor: '#430475',
    className: 'from-[#e74d6dB3] to-[#43047580]'
  },
  // Lingua dei segni
  'urn:rsi:topic:tv:80': {
    firstColor: '#1F509D',
    secondColor: '#121A37',
    className: 'from-[#1F509DB3] to-[#121A3780]'
  },
  // Audiodescrizioni
  'urn:rsi:topic:tv:90': {
    firstColor: '#8B96A5',
    secondColor: '#4F5562',
    className: 'from-[#8B96A5B3] to-[#4F556280]'
  },
  // Giovani
  'urn:rsi:topic:tv:5': {
    firstColor: '#a4ba59',
    secondColor: '#1b8161',
    className: 'from-[#a4ba59B3] to-[#1b816180]'
  },
  // Actualitad
  'urn:rtr:topic:tv:20e7478f-1ea1-49c3-81c2-5f157d6ff092': {
    firstColor: '#340101',
    secondColor: '#8F0E0F',
    className: 'from-[#340101B3] to-[#8F0E0F80]'
  },
  // Social
  'urn:rtr:topic:tv:50bb90d6-41af-4bbd-b92c-6ef5db16a9b3': {
    firstColor: '#8A0533',
    secondColor: '#812626',
    className: 'from-[#8A0533B3] to-[#81262680]'
  },
  // Cuntrasts
  'urn:rtr:topic:tv:7d7f21be-6727-4939-9126-5bca25eb3a49': {
    firstColor: '#80D2E3',
    secondColor: '#003D58',
    className: 'from-[#80D2E3B3] to-[#003D5880]'
  },
  // Battaporta
  'urn:rtr:topic:tv:2d48ba80-566c-4359-9e8d-8d9b2d570e0a': {
    firstColor: '#00A1A1',
    secondColor: '#04575B',
    className: 'from-[#00A1A1B3] to-[#04575B80]'
  },
  // Uffants
  'urn:rtr:topic:tv:dfb7ae6d-cb73-431b-a817-b1663ec2f58a': {
    firstColor: '#00F8CC',
    secondColor: '#018864',
    className: 'from-[#00F8CCB3] to-[#01886480]'
  },
  // Archiv
  'urn:rtr:topic:tv:c50140e7-5740-4c44-abd0-0f7d9ea68da7': {
    firstColor: '#A6A6A7',
    secondColor: '#2C2B2D',
    className: 'from-[#A6A6A7B3] to-[#2C2B2D80]'
  }
};